import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { DebtRepaymentPage } from '../components/debtRepayments';
import { Selector, Text } from '../components/generic';
import BlogPostCard from '../components/shared/BlogPostCard/BlogPostCard';
import BlogWalletImage from '../images/blogPosts/cardWallet.png';
import BlogGrowMoneyImage from '../images/blogPosts/cardGrowMoney.png';
import { BlogCardsContainer } from '../components/shared/BlogPostCard/BlogPostCard.style';
import { ColumnSpacer } from '../components/generic/Layouts';

function TestComponents() {
  useEffect(() => {
      if (process.env.NODE_ENV !== 'development') {
          navigate('/404');
      }
  });

  return <Text>See storybook</Text>;
  //return <GetInfoModalTemplate/>;
  //return <GetInfoModalTemplate/>;

  //return <Selector options={[{ label: "Male", value: "MALE" }, { label: "Female", value: "FEMALE" }]} />;

  // return <FuneralSummaryInfoStory/>;

  // return <StepperStory />;
  // return <ErrorPage/>;

    // return (<div>
    //         <h1>Component testing?</h1>
    //         {/* <DebtRepaymentGraphStory/> */}
    //         {/* <DebtRepaymentSummary/> */}
    //         <DebtRepaymentGraphStory/>
    //     </div>);
}

export default TestComponents
